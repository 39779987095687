import { CheckedInput, TitleInput, TextLeftInput } from "components/deployments/LaunchInputs"
import Timer from "../../../imgs/icons/Timer.svg"
import Plant from "../../../imgs/icons/Plant.svg"
import UserList from "../../../imgs/icons/UserList.svg"
import Link from "../../../imgs/icons/Link.svg"
import Info from "../../../imgs/icons/Info.svg"
import { TEN_PERCENT_BORDER } from "../../styles/styles"

const KatanaSpecs = ({ metaData, setMetaData }) => {

    const handleInputChange = (key, value) => {
        setMetaData({
            ...metaData,
            [key]: value
        });
    };

    return (
        <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <TitleInput
                    Title={"Block Time"}
                    logo={Timer}
                    placeholder={"(milliseconds)"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    name="block_time"
                    value={metaData.block_time}
                    onChange={handleInputChange}
                />
                <TextLeftInput
                    className={"mt-7"}
                    text={"Auto Mining"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    icon={Info}
                    tooltip={"Disable auto and interval mining, and mine on demand instead via an endpoint. (Recommended to leave enabled)"}
                >
                    <CheckedInput
                        placeholder="Enabled"
                        value={metaData.is_auto_mining_enabled}
                        name="is_auto_mining_enabled"
                        onChange={handleInputChange}
                    />
                </TextLeftInput>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <TitleInput
                    Title={"Chain ID"}
                    logo={Link}
                    placeholder={"KATANA by default"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    name="chain_id"
                    value={metaData.chain_id}
                    onChange={handleInputChange}
                />
                <TitleInput
                    Title={"Seed"}
                    logo={Plant}
                    placeholder={"0 by default"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    name="seed"
                    value={metaData.seed}
                    onChange={handleInputChange}
                />
                <TitleInput
                    Title={"Accounts"}
                    logo={UserList}
                    placeholder={"10 by default"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    name="accounts"
                    value={metaData.accounts}
                    onChange={handleInputChange}
                />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <TextLeftInput
                    text={"Transaction Validation"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    icon={Info}
                    tooltip={"Disable validation when executing transactions. (Recommended to leave enabled)"}
                >
                    <CheckedInput
                        placeholder="Enabled"
                        value={metaData.is_transaction_validation_enabled}
                        name="is_transaction_validation_enabled"
                        onChange={handleInputChange}
                    />
                </TextLeftInput>
                <TextLeftInput
                    text={"Disable Gas Fee"}
                    style={{ border: TEN_PERCENT_BORDER }}
                    icon={Info}
                    tooltip={"Disable charging fee when executing transactions."}
                >
                    <CheckedInput
                        placeholder="Enabled"
                        value={metaData.is_gas_fee_disabled}
                        name="is_gas_fee_disabled"
                        onChange={handleInputChange}
                    />
                </TextLeftInput>
            </div>
        </div>
    )
}

export default KatanaSpecs