import React, { useState, useEffect } from 'react';
import BackButton from "../../components/ui/BackButton";
import { applicationMarketplaceService } from "../../services/marketplace/applicationMarketplace";
import { FiCopy, FiCheck, FiExternalLink } from 'react-icons/fi';
import { truncateAddress } from "../../components/core/BaseDataTable";

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleString();
};

const StatusBadge = ({ status }) => {
  let bgColor = 'bg-gray-500';
  if (status === 'CREATED') {
    bgColor = 'bg-blue-500';
  } else if (status === 'DEPLOYED') {
    bgColor = 'bg-green-500';
  } else if (status === 'ERROR') {
    bgColor = 'bg-red-500';
  } else if (status === 'DEPLOYING') {
    bgColor = 'bg-yellow-500';
  }
  return (
    <span className={`${bgColor} text-xs font-semibold text-white px-2 py-1 rounded-full`}>
      {status}
    </span>
  );
};

const ZkSyncSepoliaSmartContractLink = ({ address }) => {
  const url = `https://sepolia.explorer.zksync.io/address/${address}`;
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-400 hover:underline flex items-center"
      title="View smart contract on zkSync Explorer"
    >
      {truncateAddress(address)}
      <FiExternalLink className="ml-1" size={14} />
    </a>
  );
};

const DAppCard = ({ app }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyUrl = async () => {
    if (app.url) {
      try {
        await navigator.clipboard.writeText(app.url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } catch (error) {
        console.error("Error copying URL:", error);
      }
    }
  };

  return (
    <div className="bg-gray-900 border border-gray-700 rounded-xl p-6 shadow-md hover:shadow-xl transition-shadow duration-300 flex flex-col justify-between">
      <div>
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold text-white">{app.name}</h3>
          <StatusBadge status={app.status} />
        </div>
        <div className="space-y-2">
          <p className="text-gray-400">
            <span className="font-medium">Template:</span> {app.template}
          </p>
          <p className="text-gray-400">
            <span className="font-medium">Identifier:</span> {app.identifier}
          </p>
          <p className="text-gray-400">
            <span className="font-medium">Owner:</span> {truncateAddress(app.metadata?.owner)}
          </p>
          <p className="text-gray-400">
            <span className="font-medium">Deployed By:</span> {app.deployed_by}
          </p>
          <p className="text-gray-400">
            <span className="font-medium">Created At:</span> {formatDate(app.created_at)}
          </p>
          <p className="text-gray-400">
            <span className="font-medium">Status Date:</span> {formatDate(app.status_date)}
          </p>
          {app.smart_contracts_addresses && app.smart_contracts_addresses.length > 0 && (
            <div className="mt-2">
              <p className="text-gray-400 font-medium">Smart Contracts:</p>
              <ul className="list-disc ml-5">
                {app.smart_contracts_addresses.map((address, index) => (
                  <li key={index}>
                    <ZkSyncSepoliaSmartContractLink address={address} />
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <div className="mt-6 flex items-center justify-between">
        {app.url && (
            app.status === 'DEPLOYED' ? (
                <a
                    href={app.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white text-black py-2 px-4 rounded-lg shadow-md focus:outline-none hover:bg-gray-200 flex items-center justify-center transition-colors duration-300"
                >
                  Visit dApp
                </a>
            ) : (
                <button
                    disabled
                    className="bg-white text-black py-2 px-4 rounded-lg shadow-md opacity-50 cursor-not-allowed flex items-center justify-center transition-opacity duration-300"
                >
                  Wait for deployment
                </button>
            )
        )}
        {app.url && (
            <button
                onClick={handleCopyUrl}
                className="ml-4 flex items-center justify-center p-3 rounded-full bg-gray-800 hover:bg-gray-700 text-gray-300 transition-colors duration-300 active:scale-95"
                title={copied ? "Copied!" : "Copy URL"}
            >
              {copied ? (
                  <FiCheck size={20} className="text-green-400 transition-all duration-300"/>
              ) : (
                  <FiCopy size={20}/>
              )}
            </button>
        )}
      </div>
    </div>
  );
};

const MydApps = () => {
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchApps = async () => {
    setLoading(true);
    setError(null);
    try {
      const deployedApps = await applicationMarketplaceService.listdApps();
      setApps(deployedApps);
    } catch (err) {
      console.error(err);
      setError("Error loading dApps.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, []);

  return (
      <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white py-12 px-4">
        <div className="mb-6 mt-4">
          <BackButton/>
        </div>
        <div className="text-center max-w-3xl mx-auto mb-12">
          <h1 className="text-4xl lg:text-5xl font-extrabold">My dApps</h1>
          <p className="mt-2 text-gray-400">Discover and manage your deployed applications.</p>
        </div>
        {loading ? (
            <p className="text-center text-gray-400">Loading dApps...</p>
        ) : error ? (
            <p className="text-center text-red-500">{error}</p>
      ) : apps.length > 0 ? (
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 max-w-6xl mx-auto">
          {apps.map(app => (
            <DAppCard key={app.id} app={app} />
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-400">No dApps deployed yet.</p>
      )}
    </div>
  );
};

export default MydApps;
