import React from 'react';
import { motion } from 'framer-motion';
import DetailedAppchainCard from 'components/deployments/DetailedAppchainCard';
import AddNewServicesCard from "../components/deployments/services/AddNewServices";


export default function AppchainsContainer({ appChains, setAppChains, fetchAppChains }) {
  if (!appChains || appChains.length === 0) {
    return (
      <div className="text-gray-400 text-center py-12 space-y-4">
        <p className="text-lg">🚀 No appchains available yet.</p>
        <p className="text-sm text-gray-500">Start by adding your first appchain to unlock its potential!</p>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      className="grid grid-cols-1 gap-8"
      transition={{ duration: 0.5 }}
    >
      {appChains.map((appChain) => (
        <motion.div key={appChain.id} className="flex flex-col gap-4">
          <DetailedAppchainCard
            key={appChain.id}
            appChain={appChain}
            setAppChains={setAppChains}
            fetchAppChains={fetchAppChains}
            onAction={()=>null}
          />
          <AddNewServicesCard appchain={appChain} />
        </motion.div>
      ))}
    </motion.div>
  );
}

