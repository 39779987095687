import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GoogleAuthComponent from "../../components/auth/GoogleAuthComponent";
import GitHubAuthComponent from "components/auth/GithubAuthComponent";
import { useStateContext } from "contexts/ContextProvider";
import loginLogo from "../../imgs/loginLogo.svg";
import quasmLogo from "../../imgs/Logo 3D light Quasm.png";

export default function LogIn() {
  const navigate = useNavigate();
  const { googleLogin, onLoginFailure, user } = useStateContext();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  // interesting gradient stuff below
  // bg-gradient-to-br
  // from-[#0B0F19]/90
  // via-[#1b1b1b]/80
  // to-[#b89240]/30

  return (
    <div
      className="
        h-screen
        overflow-hidden
        flex
        items-center
        justify-center

        font-sans
        px-4
        py-6
        sm:px-8
        sm:py-10
      "
    >
      {/* Card Container */}
      <div
        className="
          w-full
          max-w-5xl
          bg-white/10
          backdrop-blur-md
          border border-white/20
          rounded-xl
          shadow-2xl
          p-6
          sm:p-8
          md:p-10
        "
      >
        {/* Using a grid to cleanly space text & image */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 items-center">
          {/* Left Section: Title, Subtitle, Bullets, and Auth Button */}
          <div className="text-white space-y-4 md:space-y-6">
            {/* "Welcome to" + Logo on one line */}
            <div className="flex items-center gap-3">
              <span className="text-3xl md:text-4xl font-extrabold leading-tight">
                Welcome to
              </span>
              <img
                src={quasmLogo}
                alt="Quasm Logo"
                className="h-8 md:h-10 object-contain flex-shrink-0"
              />
            </div>

            {/* Subtitle */}
            <p className="text-base md:text-lg leading-snug">
              Merging Web2 &amp; Web3 — Scale your Blockchain Applications.
            </p>

            {/* Bullet Points */}
            <ul className="list-disc list-inside space-y-1 md:space-y-2 text-sm md:text-base text-white/80">
              <li>One Click Blockchain Rollup deployment</li>
              <li>Integrated Account Wallet</li>
              <li>Secure and scalable solutions</li>
            </ul>

            {/* Auth Buttons Side-by-Side on larger screens, stacked on smaller */}
            <div className="flex flex-col items-start sm:items-center gap-4">
              <GoogleAuthComponent
                login={googleLogin}
                handleError={onLoginFailure}
              />
              <GitHubAuthComponent />
            </div>
          </div>

          {/* Right Section: Hero/Illustration */}
          <div className="flex items-center justify-center">
            <img
              src={loginLogo}
              alt="Login illustration"
              className="max-w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
