import React, { useState, useEffect } from "react";
import useAppchains from "../../hooks/deployments/useappchains";
import { useParams } from "react-router-dom";
import { useStateContext } from 'contexts/ContextProvider';
import GeneralSettings from "components/project/settings/GeneralSettings";
import TeamManagement from "components/project/settings/TeamManagement";
import WalletIntegration from "components/project/settings/WalletIntegration";
import ManageProjectSubscription from "components/project/settings/ManageProjectSubscription";
import BackButton from "../../components/ui/BackButton";
import {ToastContainer} from "react-toastify";


const defaultTabs = [
    { id: "general", name: "General Settings" },
    { id: "team", name: "Team Management" },
    
];



const ProjectSettings = () => {
    const [tabs, setTabs ] = useState(defaultTabs)
    const [activeTab, setActiveTab] = useState("general");
    const { projectId } = useParams();
    const { project, fetchProject, appChains} = useAppchains(projectId);
    const { user } = useStateContext();
    
    // manage permissions to only allow creator of project to delete or cancel subscription
    useEffect(() => {
        if (project && user ){
            if(appChains.length > 0 ){
                let appChain = appChains[0]
                if (appChain.quasm_wallet_api_key){
                    setTabs((tabs) => [
                        ...tabs,
                        { id: "wallet", name: "Wallet Integration" }
                    ]);
                }
            }
            if (user.email === project.created_by){
                setTabs((tabs) => [
                    ...tabs,
                    { id: "subscription", name: "Manage Subscription" }
                ]);
            }
        }
      return () => {
        setTabs(defaultTabs)
      }
    }, [project, user, appChains])
    


    return (
        <div className="flex">
            <ToastContainer />
            <div className="mr-8 p-8 md:p-16">
                <BackButton />
            </div>
            <div className="container mx-auto px-6 py-10 bg-[#020215] text-white min-h-screen p-8 md:p-16">
                {/* Header */}
                <div className="mb-8">
                    <h1 className="text-2xl font-semibold">Project Settings</h1>
                </div>
                {/* Tabs */}
                <div className="mb-6 border-b border-gray-600">
                    <nav className="flex space-x-4">
                    {tabs.map((tab) => (
                        <button
                        key={tab.id}
                        className={`py-2 px-4 border-b-2 ${
                            activeTab === tab.id
                            ? "border-white text-white"
                            : "border-transparent text-gray-400 hover:text-white"
                        }`}
                        onClick={() => setActiveTab(tab.id)}
                        >
                        {tab.name}
                        </button>
                    ))}
                    </nav>
                </div>
                {/* Tab Content */}
                <div>
                    {activeTab === "general" && <GeneralSettings project={project} fetchProject={fetchProject} />}
                    {activeTab === "team" && <TeamManagement project={project} fetchProject={fetchProject} />}
                    {activeTab === "wallet" && <WalletIntegration appChains={appChains} />}
                    {activeTab === "subscription" && <ManageProjectSubscription project={project} fetchProject={fetchProject} />}
                </div>

            </div>
        </div>
    );
};


export default ProjectSettings;
