import React, { useState } from "react";
import { toast } from "react-toastify";
import { getStatusClass } from "./utils/appchainStatus";
import { Badge } from "@tremor/react";
import { useNavigate } from "react-router-dom";
import { getAppchainService } from "../../factories/deployments/appchainServiceFactory";
import ServiceControl from "./ServiceControl";
import {IoMdCopy} from "react-icons/io";


const ToolInfo = ({tool}) => {

  if (tool.status === "Error"){
    return(
      <span className={`ml-2 text-xs font-medium px-2 py-1 rounded-lg ${getStatusClass(tool)}`}>
        {tool.status} deploying, Contact support
      </span>
    )
  }

  if (tool.is_deploying){
    return (
      <p className={`ml-2 text-xs font-medium px-2 py-1 rounded-lg bg-green-500 text-black`}>Deploying: {tool.deployment_progress}%</p>
    )
  }

  if (tool.is_active){
    return (
      <span className={`ml-2 text-xs font-medium px-2 py-1 rounded-lg ${getStatusClass(tool)}`}>
        {tool.status}
      </span>
    )
  }
}


export const DeployableTool = ({ appchain, tool, toolImg, fetchAppChains }) => {
  const [worldAddress, setWorldAddress] = useState("");
  const navigate = useNavigate();

  const deployTool = async () => {
    try {
        const appchainService = getAppchainService(tool.identifier);
        if (tool.identifier === "torii") {
          await appchainService.deploy(appchain.project, tool.id, {
            katana_app: appchain.id,
            world_address: worldAddress,
          });
        } else {
          await appchainService.deploy(appchain.project, tool.id, { appchain: appchain.id });
        }
        await fetchAppChains() // not a good practice. better to manually modify state of appchains[0].tools[idx] and set state. but this is quick way without larger refactor
        toast.success(`${tool.name} is currently deploying`);
      } catch (error) {
        console.error(error);
        toast.error(`Failed to deploy ${tool.name}`);
      }
  };

  const navigateToServiceLogs = () => {
    navigate(`/projects/${appchain.project}/${tool.identifier}/${tool.id}/logs`);
  };

  const restartTool = async () => {
    try {
      const appchainService = getAppchainService(tool.identifier);
      await appchainService.restart(appchain.project, tool.id);
      toast.success(`${tool.name} is restarting`);
      fetchAppChains()
    } catch (error) {
      console.error(error);
      toast.error(`Failed to restart ${tool.name}`);
    }
  };

  const startTool = async () => {
    try {
      const appchainService = getAppchainService(tool.identifier);
      await appchainService.start(appchain.project, tool.id);
      window.location.reload()
      toast.success(`${tool.name} started successfully`);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to start ${tool.name}`);
    }
  };

  const stopTool = async () => {
    try {
      const appchainService = getAppchainService(tool.identifier);
      await appchainService.stop(appchain.project, tool.id);
      toast.success(`${tool.name} is stopping successfully`);
      fetchAppChains();
    } catch (error) {
      console.error(error);
      toast.error(`Failed to stop ${tool.name}`);
    }
  };

  const deleteTool = async () => {
    try {
      const appchainService = getAppchainService(tool.identifier);
      await appchainService.delete(appchain.project, tool.id);
      await fetchAppChains()
      toast.success(`${tool.name} deleted successfully`);
    } catch (error) {
      console.error(error);
      toast.error(`Failed to delete ${tool.name}`);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(tool.full_url);
    toast.success("URL copied");
  };

  console.log(tool)

  return (
    <div className="flex flex-col md:flex-row items-center justify-between p-4 rounded-lg shadow-md bg-[#0A0A22]">
      {/* Tool Information */}
      <div className="flex flex-col items-center md:items-start">
        <img src={toolImg} alt={`${tool.name} tool`} className="object-cover rounded-lg mb-2" style={{"height": "40px", "width": "40px"}} />
        <h3 className="text-white text-lg font-light mb-2 flex items-center">
          {tool.name}
          <ToolInfo tool={tool}/>
        </h3>
        {tool.full_url && tool.is_deployed && (
            <div className={"gap-2"}>
              <a
                  href={tool.full_url} target={"_blank"} rel={"noreferrer"}
                  className="text-sm text-blue-500 hover:text-blue-700 underline cursor-pointer"
              >
                {tool.full_url}
              </a>

              <button
                  onClick={handleCopyUrl}
                  className="ms-3 p-2 bg-gray-800 rounded hover:bg-gray-700 transition"
                  aria-label="Copy URL"
              >
                <IoMdCopy/>
              </button>
            </div>
        )}
      </div>

      {/* Conditional Input for Torii */}
      {tool.identifier === "torii" && appchain.is_deployed ? (
          <div className="mt-4 md:mt-0 md:ml-4 w-full md:w-auto">
            <label className="block text-gray-200 text-sm font-normal mb-2">Dojo World Address</label>
          {
            tool.world_address ?
              <p className="w-full py-2 px-3 bg-[#0B0B20] border border-input-border text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white hover:bg-[#0E0E28]">
                {tool.world_address.length > 12 ? (
                  <>
                    {tool.world_address.slice(0, 6)}...{tool.world_address.slice(-6)}
                  </>
                ) : (
                  tool.world_address
                )}
              </p>
            :
            <input
              type="text"
              value={worldAddress}
              onChange={(e) => setWorldAddress(e.target.value)}
              className="w-full py-2 px-3 bg-[#0B0B20] border border-input-border text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white hover:bg-[#0E0E28]"
              required
              disabled={tool.world_address !== null && tool.world_address !== ""}
              readOnly={tool.world_address !== null && tool.world_address !== ""}
            />
          }
        </div>
      ) : !appchain.is_deployed ? (
        <Badge color="red">Appchain is not ready yet</Badge>
      ) : null}

      {/* Service Controls */}
      {
        appchain.is_deployed && tool.is_editable
        && <ServiceControl
          appchain={appchain}
          tool={tool}
          deployTool={deployTool}
          stopTool={stopTool}
          startTool={startTool}
          restartTool={restartTool}
          deleteTool={deleteTool}
          navigateToServiceLogs={navigateToServiceLogs}
        />
      }
    </div>
  );
};
