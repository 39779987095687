import { useState, useEffect } from "react";
import projectService from "../../services/deployments/project";

function useAppchains(projectId) {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [appChains, setAppChains] = useState([]);

  const fetchProject = async () => {
    try {
      const projectData = await projectService.retrieve(projectId);
      setProject(projectData || {});
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("Project not found");
      } else {
        setError("An error occurred while fetching the project details");
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchAppChains = async () => {
    try {
      const appChainsData = await projectService.listAppchains(projectId);
      setAppChains(appChainsData);
    } catch (err) {
      console.error("Error fetching appChains:", err);
    }
  };

  useEffect(() => {
    fetchProject();
  }, [projectId]);

  useEffect(() => {
    const intervalId = setInterval(fetchAppChains, 4000);
    fetchAppChains();
    return () => clearInterval(intervalId);
  }, [projectId]);

  return {
    project,
    fetchProject,
    loading,
    error,
    appChains,
    fetchAppChains,
    setAppChains
  };
}

export default useAppchains;
