import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    const allowedOrigins = [window.location.origin];
    const isInternalNavigation = allowedOrigins.some((origin) =>
      document.referrer?.startsWith(origin)
    );
    if (isInternalNavigation) {
      navigate(-1);
    } else {
      navigate('/');
    }
    window.scrollTo(0, 0);
  };

  return (
    <button
      onClick={handleBackClick}
      className="flex items-center px-4 py-2 rounded-lg bg-[#1C1C3A] border border-white/20 text-white text-sm hover:bg-[#29294b] transition duration-200 ease-in-out shadow-md focus:ring focus:ring-blue-500 focus:outline-none"
      aria-label="Go Back"
    >
      <IoIosArrowBack className="mr-2 text-lg" />
      <span className="font-medium">Back</span>
    </button>
  );
};

export default BackButton;
