import React, { useState } from 'react';
import { ethers } from 'ethers';
import BackButton from "../../components/ui/BackButton";
import { applicationMarketplaceService } from "../../services/marketplace/applicationMarketplace";
import PrimaryButton from "../../components/PrimaryButton";
import zkSyncLogo from "../../imgs/icons/ZKSync.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const Header = () => (
  <div className="text-center max-w-3xl mx-auto">
    <h1 className="text-5xl font-bold text-white leading-tight drop-shadow-md">
      dApp Marketplace
    </h1>
    <span className="bg-blue-600 text-white text-xs font-semibold uppercase px-3 py-1 rounded-full mb-3 shadow-md">
      Alpha
    </span>
    <p className="mt-4 text-lg text-gray-300">
      Deploy complete dApp in 1-Click. Our first dApp template is a No-Loss Raffle
      to engage your community and unlock new financial opportunities.
    </p>
    <div className="mt-6 flex flex-col items-center">
      <p className="text-sm text-gray-400 mb-4">More dApp templates coming soon!</p>
      <a href="https://t.me/zachcook" target="_blank" rel="noopener noreferrer">
        <PrimaryButton className="hover:scale-105 transform transition duration-300 shadow-lg">
          Suggest a dApp Template
        </PrimaryButton>
      </a>
    </div>
  </div>
);

const NoLossRaffleDeploy = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [appName, setAppName] = useState('');
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        // Demande l'accès au wallet
        await provider.send("eth_requestAccounts", []);
        const signer = provider.getSigner();
        const address = await signer.getAddress();
        setWalletAddress(address);
      } else {
        setStatus({ message: 'Please install MetaMask', type: 'error' });
      }
    } catch (error) {
      setStatus({ message: 'Wallet connection failed', type: 'error' });
    }
  };

  const handleDeploy = async (e) => {
    e.preventDefault();
    if (!walletAddress.trim() || !appName.trim()) {
      setStatus({ message: 'Please enter a valid wallet address and application name.', type: 'error' });
      return;
    }

    setLoading(true);
    setStatus(null);
    try {
      await applicationMarketplaceService.deploy("no-loss-raffle", {
        owner: walletAddress.trim(),
        name: appName.trim(),
      });
      setSubmitted(true);
      setStatus({ message: 'Your raffle is being deployed!', type: 'success' });
    } catch (error) {
      if (error.response && error.response.status === 403){
        setStatus({ message: "You've already deployed a dApp. Please contact support", type: 'error' });
      }
      else {
        setStatus({ message: 'Deployment failed. Please try again.', type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-12 max-w-lg mx-auto bg-gray-900 p-8 rounded-lg shadow-xl transform hover:scale-[1.02] transition duration-300">
      
      <div className="flex items-center bg-gray-900 p-4 rounded-t-lg">
  {/* Logo/Icon */}
  <img
    src={zkSyncLogo}
    alt="zkSync logo"
    className="w-12 h-12 mr-3"
  />

  {/* Text block */}
  <div>
    {/* Small label */}
    <p className="text-xs uppercase tracking-wide text-gray-400">
      ZkSync Sepolia (Testnet)
    </p>

    {/* Main heading */}
    <h3 className="text-xl font-semibold text-white">
      Deploy a No-Loss Raffle
    </h3>

    {/* Demo link under heading */}
    <a
      href="https://www.youtube.com/watch?v=s-uGYSQj_LQ"
      target="_blank"
      rel="noopener noreferrer"
      className="inline-flex items-center text-sm text-blue-400 hover:text-blue-300 transition-colors mt-1"
    >
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        className="mr-1 text-white"
      />
      Demo
    </a>
  </div>
</div>





      <form onSubmit={handleDeploy} className="space-y-5">
        {/* Application Name */}
        <div>
          <label className="block text-sm font-medium text-gray-300">Application Name</label>
          <input
            type="text"
            value={appName}
            onChange={(e) => setAppName(e.target.value)}
            placeholder="Enter application name"
            disabled={loading || submitted}
            className="w-full p-3 mt-1 rounded-lg border border-gray-700 bg-gray-800 text-white
                       focus:ring-2 focus:ring-blue-500 focus:outline-none disabled:opacity-50"
          />
        </div>

        {/* Wallet Owner Address */}
        <div>
          <label className="block text-sm font-medium text-gray-300">Wallet Owner Address</label>
          <div className="relative mt-1 flex shadow-sm">
            <input
              type="text"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              placeholder="0x..."
              disabled={loading || submitted}
              className="flex-1 block w-full px-3 py-3 rounded-l-lg border border-gray-700 bg-gray-800
                         text-white focus:ring-2 focus:ring-blue-500 focus:outline-none disabled:opacity-50"
            />
            <button
              type="button"
              onClick={connectWallet}
              className="inline-flex items-center px-4 py-3 border border-gray-700 border-l-0
                         rounded-r-lg bg-blue-600 text-white hover:bg-blue-700 focus:ring-2
                         focus:ring-blue-500 focus:outline-none transition duration-300 disabled:opacity-50"
              disabled={loading || submitted}
            >
              Connect Wallet
            </button>
          </div>
        </div>

        {status && (
          <p
            className={`text-center font-medium ${
              status.type === 'error' ? 'text-red-500' : 'text-green-500'
            }`}
          >
            {status.message}
          </p>
        )}

        <PrimaryButton
          type="submit"
          className="w-full py-3 text-lg font-semibold bg-blue-600 rounded-lg hover:bg-blue-700
                     focus:ring-4 focus:ring-blue-300 transition duration-300 disabled:opacity-50"
          disabled={loading || submitted}
        >
          {loading ? 'Deploying...' : submitted ? 'dApp is deploying' : 'Deploy dApp'}
        </PrimaryButton>
      </form>
    </div>
  );
};

export default function MarketplaceHome() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#020215] to-[#12121C] text-white py-16 px-4">
      <div className="mb-6">
        <BackButton />
      </div>
      <Header />
      <NoLossRaffleDeploy />
    </div>
  );
}
