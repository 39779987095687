import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const GoogleAuthComponent = ({ login, handleError }) => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="w-full">
        <GoogleLogin
          onSuccess={login}
          onError={handleError}
          scope="profile"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="
                w-full
                flex items-center justify-center
                space-x-2
                rounded-md
                bg-white
                px-4 py-2
                text-gray-700 font-semibold
                border border-gray-300 shadow-sm
                transition-colors duration-200
                hover:bg-gray-50
                focus:outline-none
                disabled:opacity-50
              "
            >
              <img
                src="/path-to-google-logo.png"
                alt="Google"
                className="h-5 w-5"
              />
              <span>Continue with Google</span>
            </button>
          )}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default GoogleAuthComponent;
