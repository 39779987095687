function getRandomSixDigit() {
    return Math.floor(100000 + Math.random() * 900000);
  }
  
export const madaraMetaDataDefault = {
    is_gas_fee_disabled: false,
    block_time: "10",
    chain_name: "MADARA",
    // chain_id: shortString.encodeShortString(getRandomSixDigit().toString()),
    chain_id: `QUASM_${getRandomSixDigit().toString()}`,
    network: "DEVNET",
};

export const katanaMetaDataDefault = {
    is_gas_fee_disabled: false,
    block_time: "0",
    chain_name: "KATANA",
    account: "10",
    chain_id: `QUASM_${getRandomSixDigit().toString()}`,
    network: "DEVNET",
    is_auto_mining_enabled: true
};

export const anvilMetaDataDefault = {
    "is_gas_fee_disabled": false,
    "block_time": "",
    "chain_id": 31337, // default for anvil
    "accounts": 10,
    "is_auto_mining_enabled": true,
}

export const arbitrumMetaDataDefault = {
    "chain_id": 412346, // default for arbitrum
}

export const zksyncMetaDataDefault = {
    "chain_id": 270
}


export default function getAppchainDefaultMetaData(appchainFramework) {
    switch (appchainFramework){
        case "arbitrum":
            return arbitrumMetaDataDefault
        case "madara":
            return madaraMetaDataDefault
        case "zksync":
            return zksyncMetaDataDefault
        case "katana":
            return katanaMetaDataDefault;
        default:
            return {}
    }
}