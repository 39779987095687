import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import githubLogo from "../../imgs/githubLogo.svg";

const GitHubAuthComponent = () => {
  const { githubLogin } = useStateContext();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleLogin = () => {
    const url = `https://github.com/login/oauth/authorize?client_id=${process.env.REACT_APP_GITHUB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&scope=user:email,repo`;
    window.location.href = url;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code && !isProcessing) {
      setIsProcessing(true);
      githubLogin(code)
        .then(() => {
          setIsProcessing(false);
          navigate("/");
        })
        .catch(() => {
          // Optionally handle errors or redirect back
          // setIsProcessing(false);
        });
    }
  }, [githubLogin, navigate, isProcessing]);

  return (
    <div className="w-full">
      <button
        onClick={handleLogin}
        disabled={isProcessing}
        className="flex items-center justify-center w-full space-x-2 rounded-md bg-white px-4 py-2
                   text-gray-700 font-semibold border border-gray-300 shadow-sm
                   transition-colors duration-200 hover:bg-gray-50
                   focus:outline-none disabled:opacity-50"
      >
        <img
          src={githubLogo}
          alt="GitHub"
          className="w-5 h-5"
        />
        <span>Sign in with GitHub</span>
      </button>
    </div>
  );
};

export default GitHubAuthComponent;
