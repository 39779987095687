import {BaseService} from "../core/base";

export class ApplicationMarketplaceService extends BaseService{
    async deploy(applicationId, params){
        params.identifier = applicationId;
        const resp = await this.apiClient.post(
            `/marketplace/templates/deploy`,
            params
        )
        return resp.data;
    }

    async listdApps(){
        const resp = await this.apiClient.get("/marketplace/my-dapps");
        return resp.data;
    }
}


export const applicationMarketplaceService = new ApplicationMarketplaceService();
