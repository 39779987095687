import { useEffect, useState } from "react";
import projectService from "../../services/deployments/project";

export const useExplorableProjects = () => {
    const [explorableProjects, setExplorableProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExplorableProjects = async () => {
            try {
                setLoading(true);
                const projects = await projectService.getExplorableProjects();
                setExplorableProjects(projects);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchExplorableProjects();

        return () => {
        };
    }, []);

    return {
        explorableProjects,
        setExplorableProjects,
        loading,
        error,
    };
};
